import { Box } from "@mui/system";
import * as React from "react";
import NavMenu from "./navMenu/NavMenu";   // top navigation
import TrimmedNav from './trimmedNav/TrimmedNav';    // permission-trimmed side navigation

import AlertOverlay from "./AlertOverlay";

export default (props: { children?: React.ReactNode }) => (
	<>
		<NavMenu />

		<Box className="leftnav-container" display="flex" flexDirection="row" flex={1}>
			<TrimmedNav />
			<Box className="pagecontent-container" display="flex" flexDirection="row" flex={1}>
			{props.children}
			</Box>
		</Box>

		<AlertOverlay />
	</>
);
