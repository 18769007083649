import axios, { Method, AxiosResponse } from "axios";
import * as Contracts from "../resources/Contracts";
import {ICompany, ICompanyView, IUser} from "../resources/Contracts";

export class Service {
	private static async callApi<TResponse>(
		apiUrl: string,
		method: Method,
		payload?: any
	): Promise<TResponse> {
		let request: AxiosResponse<TResponse>;
		const url: string = `/api${apiUrl}`;

		switch (method) {
			case "GET":
				request = await axios.get(url);
				break;

			case "POST":
				request = await axios.post(url, payload);
				break;

			case "PUT":
				request = await axios.put(url, payload);
				break;

			case "DELETE":
				request = await axios.delete(url);
				break;
		}

		return request.data;
	}

	

	public static async getUsersData(
		request: Contracts.IUsersRequest
	): Promise<Contracts.IUsersResponse[]> {
		return Service.callApi<Contracts.IUsersResponse[]>("/users/list", "POST", request);
	}

	public static async getCompaniesDataPaged(request: Contracts.ICompaniesRequest): Promise<Contracts.ICompany[]> {
		return Service.callApi<Contracts.ICompany[]>("/companies/list", "POST", request);
	}

	public static async getUserRolesByUserId(userId: string): Promise<Contracts.IRole[]> {
		return Service.callApi<Contracts.IRole[]>(`/users/roles/${userId}`, "GET");
	}

	public static async getUserRolesByUserEmail(userEmail: string): Promise<Contracts.IUserRole[]> {
		return Service.callApi<Contracts.IUserRole[]>(`/Roles/getroles/${userEmail}`, "GET");
	}

	public static async getUserApplicationsByUserId(userId: string): Promise<Contracts.IUserApplication[]> {
		return Service.callApi<Contracts.IUserApplication[]>(`/Applications/getappsbyuserid/${userId}`, "GET");
	}

	public static async updateUser(user: IUser): Promise<Contracts.IUser> {
		return Service.callApi(`/users/${user.id}`, "PUT", user);
	}

	public static async addUser(user: IUser): Promise<Contracts.IUser> {
		return Service.callApi(`/users`, "POST", user);
	}

	public static async deleteUser(userId: string): Promise<Contracts.IUser> {
		return Service.callApi(`/users/${userId}`, "DELETE");
	}

	public static async getCompaniesTable(search?: string, pageIndex?:number, pageSize?:number): Promise<Contracts.ICompaniesResponse> {
		let methodPath: string = "/Companies/companiestable";
		let queryParams: string = formatQueryParams({searchText: search, pageIndex: pageIndex, pageSize: pageSize});
		let url = methodPath + queryParams;
		return Service.callApi<Contracts.ICompaniesResponse>(url, "GET");
	}

	public static async getCompanies(search?: string): Promise<Contracts.ICompany[]> {
		let url: string = "/Companies/getcompanies";

		if (search) {
			url += "?searchTerm=" + encodeURIComponent(search);
		}

		return Service.callApi<Contracts.ICompany[]>(url, "GET");
	}
	// this is used by CompaniesTable
	public static async getCompaniesData(search?: string): Promise<Contracts.ICompany[]> {
		let url: string = "/Companies/all";

		if (search) {
			url += "?searchTerm=" + encodeURIComponent(search);
		}

		return Service.callApi<Contracts.ICompany[]>(url, "GET");
		//return Service.callApi<Contracts.ICompany[]>(`/Companies/all`, "GET");
	}
	
	// gets the side navigation; returns data if the user is logged in an a member of the admins group
	public static async getTrimmedNavData(): Promise<Contracts.INavData[]> {
		let url: string = "/TrimmedNav";
		return Service.callApi<Contracts.INavData[]>(url, "GET");
	}

	// this is used by CompaniesTable (crm lookup)
	public static async getCrmData(search?: string): Promise<Contracts.ICompanyView[]> {
		let url: string = "/Crm/getbyname";

		if (search) {
			url += "?name=" + encodeURIComponent(search);
		}

		return Service.callApi<Contracts.ICompanyView[]>(url, "GET");
		//return Service.callApi<Contracts.ICompany[]>(`/Companies/all`, "GET");
	}

//	public static async getCompaniesData(
//		request: Contracts.ICompaniesRequest
//	): Promise<Contracts.ICompaniesResponse[]> {
//		return Service.callApi<Contracts.ICompaniesResponse[]>("/companies/list", "POST", request);
//	}

//	public static async getCompaniesData(
//		request: Contracts.ICompaniesRequest
//	): Promise<Contracts.ICompany[]> {
//		//return Service.callApi<Contracts.ICompany[]>("/companies/list", "POST", request);
	//}

	public static async addCompany(company: ICompanyView): Promise<Contracts.ICompanyView> {
		return Service.callApi(`/companies/add`, "POST", company);
	}
	public static async updateCompany(company: ICompanyView): Promise<Contracts.ICompanyView> {
		return Service.callApi(`/companies/update/${company.id}`, "PUT", company);
	}

	public static async getRoles(): Promise<Contracts.IRole[]> {
		return Service.callApi<Contracts.IRole[]>("/Roles/getroles", "GET");
	}

	public static async getApplications(): Promise<Contracts.IApplication[]> {
		return Service.callApi<Contracts.IApplication[]>("/Applications/getapps", "GET");
	}

	public static async getTemplates(): Promise<Contracts.ITemplate[]> {
		return Service.callApi<Contracts.ITemplate[]>("/templates", "GET");
	}

	public static async getCurrentUser(): Promise<string> {
		return Service.callApi<string>("/home", "GET");
	}
}

// format the query string params
function formatQueryParams(params): string {
	const Url = new URL("https://placeholder");

	const urlParams = new URLSearchParams(Url.search);
	for (const key in params) {
	  if (params[key] !== undefined && params[key] !== "") {
		urlParams.set(key, params[key]);
	  }
	}
	
	return ("?" + urlParams.toString());
}


