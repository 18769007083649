import { Action, Reducer } from "redux";

export interface CurrentUserState {
	userName: string;
}

export interface SetCurrentUserNameAction {
	type: "SET_CURRENT_USER_NAME";
	payload: string;
}

export type KnownAction = SetCurrentUserNameAction;

export const actionCreators = {
	setCurrentUserName: (name: string) =>
		({ type: "SET_CURRENT_USER_NAME", payload: name } as SetCurrentUserNameAction),
};

export const reducer: Reducer<CurrentUserState> = (
	state: CurrentUserState | undefined,
	incomingAction: Action
) => {
	if (state === undefined) {
		return { userName: null };
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SET_CURRENT_USER_NAME":
			return { userName: action.payload };
		default:
			return state;
	}
};
