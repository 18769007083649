import styles from "./TrimmedNav.module.css"
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { NavLink } from "react-router-dom";
import { useState } from "react";
import React from "react";
import { INavData } from "../../resources/Contracts";
import { Service } from "../../services/Service";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

export default function trimmedNav() {
    const [navData, setNavData] = React.useState<INavData[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [open, setopen] = useState(true);
    const toggleOpen = () => {
        setopen(!open)
    };
    
    // Load the navigation items from the controller - will be empty unless user is logged in as an admin
    const loadNavigation = React.useCallback(async () : Promise<void> => {
        setLoading(true);
        try {
			const result: INavData[] = (await Service.getTrimmedNavData());
			if (result) {
				setNavData(result);
			}
		} catch (error) {
			//props.addErrorAlert(handleErrorMessage(error));
		}
        setLoading(false);
    },[]);

    React.useEffect(() => {
		loadNavigation();
	}, [loadNavigation]);

    return (
        <div className={open?styles.trimmedNav:styles.trimmedNavClosed}>
            
            <button className={styles.menuBtn} onClick={toggleOpen}>
                {open? <KeyboardDoubleArrowLeftIcon />: <KeyboardDoubleArrowRightIcon />}
            </button>
            
            {loading ? 
            (
                <Box display="flex" className="nav-loadingIndicator">
                    <CircularProgress />
                </Box>
            ) : 
            (
                <>
                {navData.map(item =>{
                    return <NavLink key={item.id} className={styles.trimmedItem} to={item.link}>
                        <img src={item.icon}></img>
                        <span className={styles.linkText}>{item.text}</span>
                    </NavLink>
                    })
                }
                </>
            )
        }
        </div>
    )
}
