import { Autocomplete, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { ICompany, ICompanyView } from "../../../../resources/Contracts";
import Texts from "../../../../resources/Texts";
import { Service } from "../../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../../store/AlertStore";
import { handleErrorMessage } from "../../../../utils/utils";
import { TextFormField } from "../../formControls/TextFormField";
import "./CompanyDetailsTab.scss";

type CompanyDetailsTabProps = typeof AlertStoreActionCreators & {
	//dataItem: ICompany; 
	//onChange: (newCompanyValue: ICompany) => void;
	dataItem: ICompanyView; 
	onChange: (newCompanyValue: ICompanyView) => void;
};

const CompanyDetailsTab: React.FC<CompanyDetailsTabProps> = ({
	dataItem,
	onChange,
	addErrorAlert,
}) => {
	const [companies, setCompanies] = React.useState<ICompanyView[]>([]);
	const [companyValue, setCompanyValue] = React.useState<ICompanyView>(null);
	const [searchValue, setSearch] = React.useState<string>(null);

	const [crmCompanies, setCrmCompanies] = React.useState<ICompanyView[]>([]);
	const [crmCompanyValue, setCrmCompanyValue] = React.useState<ICompanyView>(null);
	const [crmSearchValue, setCrmSearch] = React.useState<string>(null);

	let companySearchTimeout: NodeJS.Timeout;
	let crmCompanySearchTimeout: NodeJS.Timeout;
/*
	const loadCompanies = React.useMemo(
		() => async (): Promise<void> => {
			try {
				const companies: ICompanyView[] = await Service.getCompanies(searchValue);

				setCompanies(companies);
			} catch (error) {
				addErrorAlert(handleErrorMessage(error));
			}
		},
		[searchValue, addErrorAlert]
	);
*/
	const loadCrmCompanies = React.useMemo(
		() => async (): Promise<void> => {
			try {
				const crmCompanies: ICompanyView[] = await Service.getCrmData(searchValue);

				setCrmCompanies(crmCompanies);
			} catch (error) {
				addErrorAlert(handleErrorMessage(error));
			}
		},
		[crmSearchValue, addErrorAlert]
	);
 /*
	React.useEffect(() => {
		loadCompanies();
	}, [loadCompanies]);
*/
	React.useEffect(() => {
		loadCrmCompanies();
	}, [loadCrmCompanies]);

 
	const handleCrmCompanyChange = (value: ICompanyView) => {
		const company: ICompanyView = { ...dataItem };
		company.companyName = value?.companyName;
		company.dynamicsId = value?.dynamicsId;
		company.description = value?.description;
		onChange(company);
		setCompanyValue(value);
	};
  

  const companyNameHandler = (value: string) => {
		const company: ICompanyView = { ...dataItem };
		company.companyName = value;
		onChange(company);
	};

  const dynamicsIdHandler = (value: string) => {
		const company: ICompanyView = { ...dataItem };
		company.dynamicsId = value;
		onChange(company);
	};

  const domainHandler = (value: string) => {
		const company: ICompanyView = { ...dataItem };
		company.domain = value;
		onChange(company);
	};

	const descriptionHandler = (value: string) => {
		const company: ICompanyView = { ...dataItem };
		company.description = value;
		onChange(company);
	};

  	const idHandler = (value: string) => {
		const company: ICompanyView = { ...dataItem };
		company.domain = value;
		//onChange(company);
	};
  
	const handleCrmCompanySearchChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	): void => {
		clearTimeout(crmCompanySearchTimeout);

		crmCompanySearchTimeout = setTimeout(() => {
			setCrmSearch(event.target.value);
		}, 500);
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="flex-start"
			className="editCompany-tab-content detailsTab"
			flex={1}
		>
			<Typography variant="h6" className="tab-title" fontFamily="Poppins">
				{Texts.CompaniesView.EditCompanyForm.Tabs.CompanyDetails.Title}
			</Typography>

			<Box display="flex" flexDirection="row" flex={1} width="100%">
				<Box
					display="flex"
					flexDirection="column"
					flex={1}
					className="editCompany-detailColumn"
				>
					
          <TextFormField
						className="editCompany-formField"
						disabled
						label={Texts.CompaniesView.EditCompanyForm.Tabs.CompanyDetails.CompanyName}
						value={dataItem?.companyName}
						onChange={companyNameHandler}
					/>
		  <TextFormField
						className="editCompany-formField"
						label={Texts.CompaniesView.EditCompanyForm.Tabs.CompanyDetails.Description}
						value={dataItem?.description}
						onChange={descriptionHandler}
					/>
          <TextFormField
						className="editCompany-formField"
						disabled
						label={Texts.CompaniesView.EditCompanyForm.Tabs.CompanyDetails.DynamicsId}
						value={dataItem?.dynamicsId}
						onChange={dynamicsIdHandler}
					/>
          <TextFormField
						className="editCompany-formField"
						label={Texts.CompaniesView.EditCompanyForm.Tabs.CompanyDetails.Domain}
						value={dataItem?.domain}
						onChange={domainHandler}
					/>
				</Box>
				<Box display="flex" flexDirection="column" className="filler" flex={1} />
			</Box>
		</Box>
	);
};

export default connect(null, AlertStoreActionCreators)(CompanyDetailsTab);
