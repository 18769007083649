import { Box } from "@mui/system";
import * as React from "react";
import SynamediaTitleImg from "../../images/synamedia-logo.png";
import LoginButton from "./LoginButton";
import "./NavMenu.scss";
import Texts from "../../resources/Texts";


const NavMenu: React.FunctionComponent<{}> = (props) => {
	return (
		<header className="app-header">
			<Box
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				display="flex"
				className="header-wrapper"
			>
				<Box
					flexDirection="row"
					display="flex"
					alignItems="center"
					className="header-column header-left"
				>
					<Box className="header-link-box" display="flex" alignItems="center">
						<img src={SynamediaTitleImg} alt="titleImg" />
					</Box>
				</Box>
				<Box
					flexDirection="row"
					display="flex"
					alignItems="center"
					className="header-column header-middle"

				>
					<div className="AppName">{Texts.Navigation.SID}</div>
				</Box>

				<Box
					flexDirection="row"
					display="flex"
					alignItems="center"
					className="header-column header-right"
				>
					<LoginButton />
				</Box>
			</Box>
		</header>
	);
};

export default NavMenu;
