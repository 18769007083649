export function handleErrorMessage(error: Error): string {
	console.log(JSON.stringify(error.name));
	console.log(JSON.stringify(error.message));

	if (error.message) {
    	return "There was a problem - SID could not complete the requested action";
	} else if (error.name) {
		return error.name;
	} else {
		return JSON.stringify(error);
	}
}
