import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import Texts from "../../resources/Texts";
import { ApplicationState } from "../../store";
import { CurrentUserState } from "../../store/CurrentUser";
import "./LoginButton.scss";

type LoginButtonProps = CurrentUserState & {};

const LoginButton: React.FC<LoginButtonProps> = (props) => {
	const handleLogout = (): void => {
		window.location.href = "/api/auth/logout";
	};

	const handleLogin = async () => {
		window.location.href = "/api/auth";
	};

	return (
		<Box className="header-link-box login-button-wrapper" display="flex" alignItems="center">
			{props.userName ? (
				<Box>
					{props.userName}
					<span onClick={handleLogout} className="login-button">
						{Texts.Navigation.Logout}
					</span>
				</Box>
			) : (
				<Box onClick={handleLogin} className="login-button">
					{Texts.Navigation.Login}
				</Box>
			)}
		</Box>
	);
};

export default connect((state: ApplicationState) => state.currentUser)(LoginButton);
