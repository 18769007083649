import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import "./UserAdministration.scss";
import UsersTable from "../../../components/administration/users/UsersTable";
import Texts from "../../../resources/Texts";

interface UserAdministrationProps {}

const UserAdministration: React.FunctionComponent<UserAdministrationProps> = () => {
	return (
		<Box className="userAdministration">
      <Container maxWidth={false} disableGutters>
				<Box display="flex" flexDirection="column" className="userAdministration-title">
					<Typography variant="h4" component="div" fontFamily="Poppins">
						{Texts.UsersView.Title}
					</Typography>

					<Typography variant="subtitle1" component="div" fontFamily="Poppins">
						{Texts.UsersView.Subtitle}
					</Typography>
				</Box>

				<UsersTable />
			</Container>
		</Box>
	);
};

export default UserAdministration;
