const isDomainValid = async (domain: string): Promise<boolean> => {
    try {
        const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/;
        if (!domainRegex.test(domain)) return false;

        const response = await fetch(`https://dns.google/resolve?name=${domain}`);
        const data = await response.json();
        return data.Status === 0;
    } catch (error) {
        return false;
    }
}

export default isDomainValid;
