import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { ICompany, ICompanyView } from "../../../resources/Contracts";
//import Texts from "../../../resources/Texts";
//import { Service } from "../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
//import { handleErrorMessage } from "../../../utils/utils";
import EditCompanyForm from "./companyForms/EditCompanyForm";
import "./CompaniesTableRow.scss";

type CompaniesTableRowProps = typeof AlertStoreActionCreators & {
	//dataItem: ICompany; 
	//updateCompany: (company: ICompany) => void;

	dataItem: ICompanyView; 
	updateCompany: (company: ICompanyView) => void;
};

const CompaniesTableRow: React.FC<CompaniesTableRowProps> = (props) => {
	const [detailOpened, setDetailOpened] = React.useState<boolean>();

	const handleOpen = (): void => {
		setDetailOpened(true);
	};

	const handleClose = (): void => {
		setDetailOpened(false);
	};

	const actionsColumn = (): JSX.Element => {
		return (
			<Box>
				<IconButton onClick={handleOpen}>
					<EditIcon />
				</IconButton>
			</Box>
		);
	};

	let rowClassName: string = "companiesTable-row";

	if (detailOpened) {
		rowClassName = rowClassName + " opened";
	}

	return (
		<>
			<TableRow key={props.dataItem.id} className={rowClassName}>
				<TableCell align="left">{props.dataItem.companyName}</TableCell>
				<TableCell align="left">{props.dataItem.dynamicsId}</TableCell>
				<TableCell align="left">{props.dataItem.domain}</TableCell>
        <TableCell align="left">{props.dataItem.description}</TableCell>
				<TableCell align="right">{actionsColumn()}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell sx={{ padding: 0 }} colSpan={6}>
					<EditCompanyForm
						opened={detailOpened}
						closeCallback={handleClose}
						dataItem={props.dataItem}
						updateCompany={props.updateCompany}
					/>
				</TableCell>
			</TableRow>
		</>
	);
};

export default connect(null, AlertStoreActionCreators)(CompaniesTableRow);
