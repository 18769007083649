import { Button, Collapse, IconButton, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Texts from "../../../../resources/Texts";
import AssignedApplicationsTab from "./AssignedApplicationsTab";
import AssignedRolesTab from "./AssignedRolesTab";
import { PasswordTab } from "./PasswordTab";
import PersonalDetailsTab from "./PersonalDetailsTab";
import "./EditUserForm.scss";
import PersonIcon from "@mui/icons-material/Person";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import KeyIcon from "@mui/icons-material/Key";
import { IUser } from "../../../../resources/Contracts";
import { Service } from "../../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../../store/AlertStore";
import { handleErrorMessage } from "../../../../utils/utils";
import { connect } from "react-redux";

enum EditUserFormTabs {
	Applications,
	Roles,
	Password,
	Details,
}

type EditUserFormProps = typeof AlertStoreActionCreators & {
	opened: boolean;
	closeCallback: () => void;
	dataItem: IUser;
	updateUser: (user: IUser) => void;
};

const EditUserForm: React.FC<EditUserFormProps> = (props) => {
	const [selectedTab, setSelectedTab] = React.useState<EditUserFormTabs>(
		EditUserFormTabs.Details
	);
	const [user, setUser] = React.useState<IUser>(props.dataItem);

	const handleTabChange = (event, value: EditUserFormTabs): void => {
		setSelectedTab(value);
	};

	const handleCancelClick = (): void => {
		setUser(props.dataItem);

		props.closeCallback();
	};

	const handleSaveClick = async (): Promise<void> => {
		try {
			await Service.updateUser(user);

			props.updateUser(user);
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));
		}

		props.closeCallback();
	};

	const handleChange = (newUserValue: IUser) => {
		setUser(newUserValue);
	};

	return (
		<Collapse in={props.opened} unmountOnExit sx={{ width: "100%" }}>
			<Box display="flex" flexDirection="column" className="editUserForm-container" flex={1}>
				<Box
					className="editUserForm-header"
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="flex-end"
				>
					<Tabs
						value={selectedTab}
						onChange={handleTabChange}
						className="editUserForm-tabs"
						TabIndicatorProps={{
							hidden: true,
						}}
					>
						<Tab
							label={
								<Box
									display="flex"
									flexDirection="row"
									className="tab-label"
									alignItems="center"
								>
									<ToggleOffIcon className="icon icon-toggle" />
									{Texts.UsersView.EditUserForm.Tabs.AssignedApps.TabLabel}
								</Box>
							}
							value={EditUserFormTabs.Applications}
							className="tab"
						/>
						<Tab
							label={
								<Box
									display="flex"
									flexDirection="row"
									className="tab-label"
									alignItems="center"
								>
									<ToggleOffIcon className="icon icon-toggle" />
									{Texts.UsersView.EditUserForm.Tabs.AssignedRoles.TabLabel}
								</Box>
							}
							value={EditUserFormTabs.Roles}
							className="tab"
						/>
						<Tab
							label={
								<Box
									display="flex"
									flexDirection="row"
									className="tab-label"
									alignItems="center"
								>
									<KeyIcon className="icon icon-key" />
									{Texts.UsersView.EditUserForm.Tabs.Password.TabLabel}
								</Box>
							}
							value={EditUserFormTabs.Password}
							className="tab"
						/>
						<Tab
							label={
								<Box
									display="flex"
									flexDirection="row"
									className="tab-label"
									alignItems="center"
								>
									<PersonIcon className="icon icon-person" />
									{Texts.UsersView.EditUserForm.Tabs.PersonalDetails.TabLabel}
								</Box>
							}
							value={EditUserFormTabs.Details}
							className="tab"
						/>
					</Tabs>

					<IconButton onClick={props.closeCallback}>
						<CloseIcon />
					</IconButton>
				</Box>

				{selectedTab === EditUserFormTabs.Applications && (
					<AssignedApplicationsTab dataItem={user} onChange={handleChange} />
				)}

				{selectedTab === EditUserFormTabs.Roles && (
					<AssignedRolesTab dataItem={user} onChange={handleChange} />
				)}

				{selectedTab === EditUserFormTabs.Password && (
					<PasswordTab dataItem={user} onChange={handleChange} />
				)}

				{selectedTab === EditUserFormTabs.Details && (
					<PersonalDetailsTab dataItem={user} onChange={handleChange} />
				)}

				<Box
					display="flex"
					flexDirection="row"
					justifyContent="flex-end"
					className="buttons"
				>
					<Button
						className="button secondary-button"
						variant="contained"
						onClick={handleCancelClick}
					>
						{Texts.UsersView.EditUserForm.Buttons.Cancel}
					</Button>

					<Button
						className="button primary-button"
						variant="contained"
						onClick={handleSaveClick}
					>
						{Texts.UsersView.EditUserForm.Buttons.Save}
					</Button>
				</Box>
			</Box>
		</Collapse>
	);
};

export default connect(null, AlertStoreActionCreators)(EditUserForm);
