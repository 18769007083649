import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { Route } from "react-router-dom";
//import './App.css';
import TrimmedNav from '../../components/trimmedNav/TrimmedNav';
import Home from "./HomeView";
import UserAdministration from "../administration/users/UserAdministration";
import CompanyAdministration from "../administration/companies/CompanyAdministration";
//import Statistics from "./Pages/Statistics";


import "./HomeView.scss";
//import UsersTable from "../../../components/administration/users/UsersTable";
import Texts from "../../resources/Texts";

interface HomeViewProps {}

const HomeView: React.FunctionComponent<HomeViewProps> = () => {
    return (
        <Box className="homeView">
            <Container maxWidth={false} disableGutters>
                <Box display="flex" flexDirection="column" className="homeView-title">
                    <Typography variant="h4" component="div" fontFamily="Poppins">
                        {Texts.HomeView.Title}
                    </Typography>

                    <Typography variant="subtitle1" component="div" fontFamily="Poppins">
                        {Texts.HomeView.Subtitle}
                    </Typography>
                </Box>

            </Container>
        </Box>
    );
};

export default HomeView;
