import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	IconButton,
	Switch,
	TableCell,
	TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { IUser } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import { handleErrorMessage } from "../../../utils/utils";
import EditUserForm from "./userForms/EditUserForm";
import "./UsersTableRow.scss";

type UsersTableRowProps = typeof AlertStoreActionCreators & {
	dataItem: IUser;
	updateUser: (user: IUser) => void;
	deleteUser: (userId: string) => void;
};

const UsersTableRow: React.FC<UsersTableRowProps> = (props) => {
	const [detailOpened, setDetailOpened] = React.useState<boolean>();
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
	const [openEnableDialog, setOpenEnableDialog] = React.useState<boolean>(false);
	const [openDisableDialog, setOpenDisableDialog] = React.useState<boolean>(false);

	const handleOpen = (): void => {
		setDetailOpened(true);
	};

	const handleClose = (): void => {
		setDetailOpened(false);
	};

	const handleSwitchAccountEnabled = (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	): void => {
		if (checked) {
			setOpenEnableDialog(true);
		} else {
			setOpenDisableDialog(true);
		}
	};

	const switchColumn = (): JSX.Element => {
    const label: string = props.dataItem.accountEnabled ? "Enabled" : "Disabled";
		return (
			<FormControlLabel
				className="status-switch"
				control={
					<Switch
						id={props.dataItem.id}
						checked={props.dataItem.accountEnabled}
						onChange={handleSwitchAccountEnabled}
					/>
				}
				label={label}
				sx={{ width: "125px" }}
			/>
		);
	};

	const deleteClickHandler = (): void => {
		setOpenDeleteDialog(true);
	};

	const handleDeleteUser = async (): Promise<void> => {
		setOpenDeleteDialog(false);

		try {
			await Service.deleteUser(props.dataItem.id);
			props.deleteUser(props.dataItem.id);
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));
		}
	};

	const handleUserEnabledChange = async (): Promise<void> => {
		setOpenDisableDialog(false);
		setOpenEnableDialog(false);

		try {
			const userCopy: IUser = {
				...props.dataItem,
				accountEnabled: !props.dataItem.accountEnabled,
			};

			await Service.updateUser(userCopy);
			props.updateUser(userCopy);
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));
		}
	};

	const actionsColumn = (): JSX.Element => {
		return (
			<Box>
				<IconButton onClick={handleOpen}>
					<EditIcon />
				</IconButton>

				<IconButton onClick={deleteClickHandler}>
					<DeleteIcon />
				</IconButton>
			</Box>
		);
	};

	let rowClassName: string = "usersTable-row";

	if (!props.dataItem.accountEnabled) {
		rowClassName = rowClassName + " disabled";
	}

	if (detailOpened) {
		rowClassName = rowClassName + " opened";
	}

	return (
		<>
			<TableRow key={props.dataItem.displayName} className={rowClassName}>
				<TableCell align="left">{props.dataItem.displayName}</TableCell>
				<TableCell align="left">{props.dataItem.companyName}</TableCell>
				<TableCell align="left">
					<Box className="loginColumn-content">{props.dataItem.mail}</Box>
				</TableCell>
				<TableCell align="left">{switchColumn()}</TableCell>
				<TableCell align="right">{actionsColumn()}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell sx={{ padding: 0 }} colSpan={6}>
					<EditUserForm
						opened={detailOpened}
						closeCallback={handleClose}
						dataItem={props.dataItem}
						updateUser={props.updateUser}
					/>
				</TableCell>
			</TableRow>

			<Dialog
				open={openDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="dialog"
			>
				<DialogTitle className="dialog-title">{Texts.Dialogs.DeleteUserTitle}</DialogTitle>

				<DialogContent>
					<DialogContentText
						className="dialog-message-content"
						display="flex"
						flexDirection="column"
						alignItems="center"
					>
						<Box>{Texts.Dialogs.ConfirmDeleteText}</Box>
						<b>{props.dataItem.displayName}</b>
						<Box>{Texts.Dialogs.ConfirmationText}</Box>
					</DialogContentText>
				</DialogContent>

				<DialogActions className="dialog-actions">
					<Button
						className="button secondary-button"
						onClick={() => setOpenDeleteDialog(false)}
					>
						{Texts.Dialogs.Buttons.CancelDialog}
					</Button>

					<Button
						className="button button-dialogConfirm"
						onClick={handleDeleteUser}
						autoFocus
					>
						{Texts.Dialogs.Buttons.DeleteAccount}
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openEnableDialog}
				onClose={() => setOpenEnableDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="dialog"
			>
				<DialogTitle className="dialog-title">{Texts.Dialogs.ConfirmEnable}</DialogTitle>

				<DialogContent>
					<DialogContentText
						className="dialog-message-content"
						display="flex"
						flexDirection="column"
						alignItems="center"
					>
						<Box>{Texts.Dialogs.ConfirmEnableText}</Box>
						<b>{props.dataItem.displayName}</b>
						<Box>{Texts.Dialogs.ConfirmationText}</Box>
					</DialogContentText>
				</DialogContent>

				<DialogActions className="dialog-actions">
					<Button
						className="button secondary-button"
						onClick={() => setOpenEnableDialog(false)}
					>
						{Texts.Dialogs.Buttons.CancelDialog}
					</Button>

					<Button
						className="button button-dialogConfirm"
						onClick={handleUserEnabledChange}
						autoFocus
					>
						{Texts.Dialogs.Buttons.EnableAccount}
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openDisableDialog}
				onClose={() => setOpenDisableDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="dialog"
			>
				<DialogTitle className="dialog-title">{Texts.Dialogs.ConfirmDisable}</DialogTitle>

				<DialogContent>
					<DialogContentText
						className="dialog-message-content"
						display="flex"
						flexDirection="column"
						alignItems="center"
					>
						<Box>{Texts.Dialogs.ConfirmDisableText}</Box>
						<b>{props.dataItem.displayName}</b>
						<Box>{Texts.Dialogs.ConfirmationText}</Box>
					</DialogContentText>
				</DialogContent>

				<DialogActions className="dialog-actions">
					<Button
						className="button secondary-button"
						onClick={() => setOpenDisableDialog(false)}
					>
						{Texts.Dialogs.Buttons.CancelDialog}
					</Button>

					<Button
						className="button button-dialogConfirm"
						onClick={handleUserEnabledChange}
						autoFocus
					>
						{Texts.Dialogs.Buttons.DisableAccount}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default connect(null, AlertStoreActionCreators)(UsersTableRow);
