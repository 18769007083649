import CompanyAddIcon from "@mui/icons-material/Castle";
import CloseIcon from "@mui/icons-material/Close";
import {
	Autocomplete,
	Button,
	Collapse,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { ICompany, ICompanyView } from "../../../../resources/Contracts";
import { EmailTemplate } from "../../../../resources/Enums";
import Texts from "../../../../resources/Texts";
import { Service } from "../../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../../store/AlertStore";
import { handleErrorMessage } from "../../../../utils/utils";
import { MultiselectFormField } from "../../formControls/MultiselectFormField";
import { TextFormField } from "../../formControls/TextFormField";
import "./NewCompanyForm.scss";
import isDomainValid from "../../../shared/utils";

type NewCompanyFormProps = typeof AlertStoreActionCreators & {
	opened: boolean;
	reloadCompanies: () => void;
	closeCallback: () => void;
};

const blankCompany: ICompanyView = {
	id: null,
  	companyName: "",
 	dynamicsId: "",
  	description: "",
  	domain: ""
};

const NewCompanyForm: React.FC<NewCompanyFormProps> = (props) => {
	const [companiesCrm, setCompaniesCrm] = React.useState<ICompanyView[]>([]);
	const [company, setCompany] = React.useState<ICompanyView>();
	const [companyValue, setCompanyValue] = React.useState<ICompanyView>(null);

	let companySearchTimeout: NodeJS.Timeout;

	React.useEffect(() => {
		loadCompanies();
		setCompany(blankCompany);
	}, []);
	
	const loadCompanies = async (search?: string): Promise<void> => {
		try {
			const validCrmCompanies: ICompanyView[] = await Service.getCrmData(search);
			setCompaniesCrm(validCrmCompanies);
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));
		}
	};

	const handleCancelClick = (): void => {
		setCompany(blankCompany);
		props.closeCallback();
	};

	const handleCrmCompanyChange = (value: ICompanyView) => {
		const newCompany: ICompanyView = { ...company };
		newCompany.companyName = value?.companyName;
		newCompany.dynamicsId = value?.dynamicsId;
		newCompany.description = value?.description;
		newCompany.domain = value?.domain;
		setCompanyValue(newCompany);
	};

	const handleCreateClick = async (): Promise<void> => {
		try {
			let continueProcessing = true;
			let errorText = "Cannot create company - please complete the missing fields: ";
			if (companyValue.companyName == null) {
				errorText += "[Company] ";
				continueProcessing = false;
			}
			if (companyValue.dynamicsId == null) {
				errorText += "[SynaCRM Account Number] ";
				continueProcessing = false;
			}
			if (companyValue.domain == null) {
				errorText += "[Domain] ";
				continueProcessing = false;
			} else {
				if (!await isDomainValid(companyValue.domain)) {
					const dnsError = `${companyValue.domain} is not a valid domain. `;
					errorText = continueProcessing ? dnsError: dnsError + errorText;
					continueProcessing = false;
				}
			}

			if (continueProcessing) {
				await Service.addCompany(companyValue);
				props.reloadCompanies();
				props.addAlert({type: "info", text: "Company " + companyValue.companyName + " was added successfully",});
			} else {
				props.addAlert({type: "warning", text: errorText});
			}
		} catch (error) {
			props.addErrorAlert(error.response.data[1]);
		}
	};

  const dynamicsIdHandler = (value: string) => {
		//const newCompany: ICompany = { ...company };
		//newCompany.dynamicsId = value;
		//setCompanyValue(newCompany);
	};

  const domainHandler = (value: string) => {
		const newCompany: ICompanyView = { ...companyValue };
		newCompany.domain = value;
		setCompanyValue(newCompany);
	};

	const handleCompanySearchChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	): void => {
		clearTimeout(companySearchTimeout);

		companySearchTimeout = setTimeout(() => {
			loadCompanies(event.target.value);
		}, 500);
	};
  	
	return (
		<Collapse in={props.opened} unmountOnExit sx={{ width: "100%" }}>
			<Box display="flex" flexDirection="column" className="newCompany-container" flex={1}>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					
					<Typography variant="h6" fontFamily="Poppins">
						{Texts.CompaniesView.NewCompanyForm.CreateNewCompany}
					</Typography>

					<IconButton onClick={props.closeCallback}>
						<CloseIcon />
					</IconButton>
				</Box>

				<Box display="flex" flexDirection="row" className="newCompany-columns" flex={1}>
					<CompanyFormColumn
						title="{Texts.CompaniesView.NewCompanyForm.CompanyDetails.Title}"
						icon={<CompanyAddIcon className="icon icon-addCompany" />}
					>
						<Autocomplete
						disablePortal
						className="formField newCompany-formField"
						options={companiesCrm || []}
						value={companyValue}
						renderInput={(params) => (
							<TextField
								{...params}
								label={Texts.CompaniesView.NewCompanyForm.CompanyDetails.CompanyName}
								onChange={handleCompanySearchChange}
								onBlur={() => loadCompanies()}
							/>
						)}
						getOptionLabel={(option: ICompanyView) => option.companyName}
						onChange={(event, value) => handleCrmCompanyChange(value as ICompanyView)}
          				/>
						<TextFormField
							className="newUser-formField"
							label={Texts.CompaniesView.NewCompanyForm.CompanyDetails.Domain}
							value={companyValue?.domain}
							onChange={domainHandler}
						/>
					
						<TextFormField
							className="newUser-formField"
							label={Texts.CompaniesView.NewCompanyForm.CompanyDetails.DynamicsId}
							value={companyValue?.dynamicsId}
							onChange={dynamicsIdHandler}
						/>
					</CompanyFormColumn>
				</Box>

				<Box
					display="flex"
					flexDirection="row"
					justifyContent="flex-end"
					alignItems="center"
				>
					<Button
						className="button secondary-button"
						variant="contained"
						onClick={handleCancelClick}
					>
						{Texts.CompaniesView.NewCompanyForm.Cancel}
					</Button>

					<Button
						className="button primary-button"
						variant="contained"
						onClick={handleCreateClick}
					>
						{Texts.CompaniesView.NewCompanyForm.Create}
					</Button>
				</Box>
			</Box>
		</Collapse>
	);
};

/*
<TextFormField
							className="newUser-formField"
							disabled
							label={Texts.CompaniesView.NewCompanyForm.CompanyDetails.CompanyName}
							value={companyValue?.companyName}
							onChange={companyNameHandler}
						/>
						<TextFormField
							className="newUser-formField"
							disabled
							label={Texts.CompaniesView.NewCompanyForm.CompanyDetails.DynamicsId}
							value={companyValue?.dynamicsId}
							onChange={dynamicsIdHandler}
						/>
						<TextFormField
							className="newUser-formField"
							label={Texts.CompaniesView.NewCompanyForm.CompanyDetails.Domain}
							value={companyValue?.domain}
							onChange={domainHandler}
						/>
						<TextFormField
							className="newUser-formField"
							label={Texts.CompaniesView.NewCompanyForm.CompanyDetails.Description}
							value={companyValue?.description}
							onChange={descriptionHandler}
						/>
*/


export default connect(null, AlertStoreActionCreators)(NewCompanyForm);

interface CompanyFormColumnProps {
	title: string;
	icon: JSX.Element;
}

const CompanyFormColumn: React.FC<CompanyFormColumnProps> = (props) => {
	return (
		<Box className="newCompany-column" display="flex" flexDirection="column" flex={1}>
			<Box
				className="newCompany-column-header"
				display="flex"
				flexDirection="row"
				alignItems="center"
			>
				
			</Box>

			{props.children}
		</Box>
	);
};
