import { Action, Reducer } from "redux";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SelectedSolutionState {
	selectedSolutionId: string | null;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetSolutionAction {
	type: "SET_SELECTED_SOLUTION";
	selectedSolutionId: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetSolutionAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	setSelectedSolutionId: (id: string | null) =>
		({ type: "SET_SELECTED_SOLUTION", selectedSolutionId: id } as SetSolutionAction)
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<SelectedSolutionState> = (
	state: SelectedSolutionState | undefined,
	incomingAction: Action
): SelectedSolutionState => {
	if (state === undefined) {
		return { selectedSolutionId: null };
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SET_SELECTED_SOLUTION":
			return { selectedSolutionId: action.selectedSolutionId };
		default:
			return state;
	}
};
