import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { Button, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { ITemplate, IUser } from "../../../../resources/Contracts";
import Texts from "../../../../resources/Texts";
import { TextFormField } from "../../formControls/TextFormField";
import { PasswordHelper } from "./PasswordHelper";
import "./PasswordTab.scss";

interface PasswordTabProps {
	dataItem: IUser;
	onChange: (newUserValue: IUser) => void;
}

export const PasswordTab: React.FC<PasswordTabProps> = (props) => {
	const passwordHandler = (value: string) => {
		const user: IUser = { ...props.dataItem };
		user.password = value;
		props.onChange(user);
	};

	const suggestClickHandler = (): void => {
		const newPass = PasswordHelper.GenerateNewPassword(4, 4, 4);
		const user: IUser = { ...props.dataItem };
		user.password = newPass;
		props.onChange(user);
	};

	return (
		<Box
			display="flex"
			flexDirection="row"
			alignItems="flex-start"
			className="editUser-tab-content passwordTab"
		>
			<Box display="flex" flexDirection="column" alignItems="flex-start" flex={1}>
				<Typography variant="h6" className="tab-title" fontFamily="Poppins">
					{Texts.UsersView.EditUserForm.Tabs.Password.ResetPasswordTitle}
				</Typography>

				<Box display="flex" flexDirection="column" className="hint">
					<Box>{Texts.UsersView.EditUserForm.Tabs.Password.PasswordHint}</Box>
					<Box>{Texts.UsersView.EditUserForm.Tabs.Password.PasswordActionNotice}</Box>
				</Box>

				<TextFormField
					value={props.dataItem.password}
					onChange={passwordHandler}
					className="password-field"
				/>

				<Button
					variant="contained"
					className="suggestPassword-button button"
					onClick={suggestClickHandler}
				>
					<LightbulbIcon />
					{Texts.UsersView.NewUserForm.InitialPassword.Suggest}
				</Button>
			</Box>

			<Box display="flex" className="filler" flex={1}></Box>
		</Box>
	);
};
