import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import Texts from "../resources/Texts";

interface UnauthorizesAccessProps { }

const UnauthorizedAccess: React.FunctionComponent<UnauthorizesAccessProps> = () => {
	return (
		<Box className="unauthorized">
			<Container>
				<Box display="flex" flexDirection="column" className="unauthorized-title">
					<Typography variant="h4" component="div">
						{Texts.UnauthorizedAccessMessage}
					</Typography>
				</Box>
			</Container>
		</Box>
	);
};

export default UnauthorizedAccess;
