export class PasswordHelper {
	public static GenerateNewPassword(
		lowercase: number,
		uppercase: number,
		numerics: number
	): string {
		const lowers: string = "abcdefghijklmnopqrstuvwxyz";
		const uppers: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const numbers: string = "0123456789";

		let generated: string[] = [];
		for (let i: number = 1; i <= lowercase + uppercase + numerics; i++) {
			generated.push("!");
		}

		let j: number = 0;
		while (j < 4) {
			const index = Math.floor(Math.random() * generated.length);
			if (generated[index] === "!") {
				generated[index] = lowers[Math.floor(Math.random() * lowers.length)];
				j++;
			}
		}

		j = 0;
		while (j < 4) {
			const index = Math.floor(Math.random() * generated.length);
			if (generated[index] === "!") {
				generated[index] = uppers[Math.floor(Math.random() * uppers.length)];
				j++;
			}
		}

		j = 0;
		while (j < 4) {
			const index = Math.floor(Math.random() * generated.length);
			if (generated[index] === "!") {
				generated[index] = numbers[Math.floor(Math.random() * numbers.length)];
				j++;
			}
		}

		let generatedString: string = "";
		generated.forEach((c) => (generatedString += c));
		return generatedString;
	}
}
