import * as React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import Layout from "./components/Layout";
import { Routing } from "./resources/Routes";
import { ApplicationState } from "./store";
import { CurrentUserState } from "./store/CurrentUser";
import "./styles/custom.scss";
import UserAdministration from "./views/administration/users/UserAdministration";
import CompanyAdministration from "./views/administration/companies/CompanyAdministration";
import UnauthorizedView from "./views/UnauthorizedView";
import UnauthorizedAccess from "./views/UnauthorizesAccess";
import HomeView from "./views/home/HomeView";

type AppProps = CurrentUserState & {};

const App: React.FunctionComponent<AppProps> = (props) => {
	const routes: JSX.Element[] = [];

	routes.push(
		<Route
			exact
			path="/Unauthorized"
			component={UnauthorizedAccess}
			key="unauthorized"
		/>
	);

	if (props.userName) {
		routes.push(
			<Route exact path={Routing.Routes.HomePage} component={HomeView} key="home" />
		);
		routes.push(
			<Route exact path={Routing.Routes.Users} component={UserAdministration} key="users" />
		);
		routes.push(
			<Route exact path={Routing.Routes.Companies} component={CompanyAdministration} key="companies" />
		);
	} else {
		routes.push(
			<Route
				exact
				path={Routing.Routes.HomePage}
				component={UnauthorizedView}
				key="unauthorized"
			/>
		);
	}

	return (
		<Layout>
			<Switch>
				{routes}

				<Redirect to={Routing.Routes.HomePage} />
			</Switch>
		</Layout>
	);
};

export default connect((state: ApplicationState) => state.currentUser)(App);
