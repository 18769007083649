import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { IRole, IUser, IUserRole } from "../../../../resources/Contracts";
import Texts from "../../../../resources/Texts";
import { Service } from "../../../../services/Service";
import { MultiselectFormField } from "../../formControls/MultiselectFormField";
import "./AssignedRolesTab.scss";
import { actionCreators as AlertStoreActionCreators } from "../../../../store/AlertStore";
import { handleErrorMessage } from "../../../../utils/utils";
import { connect } from "react-redux";

type AssignedRolesTabProps = typeof AlertStoreActionCreators & {
	dataItem: IUser;
	onChange: (newUserValue: IUser) => void;
};

const AssignedRolesTab: React.FC<AssignedRolesTabProps> = (props) => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [roles, setRoles] = React.useState<IRole[]>([]);
	const [selectedRoles, setSelectedRoles] = React.useState<IRole[]>([]);

	React.useEffect(() => {
		const loadRoles = async (): Promise<void> => {
			setLoading(true);

			try {
				const result: IRole[] = await Service.getRoles();

				const userRoles: IUserRole[] = await Service.getUserRolesByUserEmail(
					props.dataItem.mail
				);

				const selectedUserRoles = result.filter((role) =>
					userRoles?.some((ur) => role.id === ur.roleId)
				);

				setRoles(result);
				setSelectedRoles(selectedUserRoles);
			} catch (error) {
				props.addErrorAlert(handleErrorMessage(error));
			}

			setLoading(false);
		};

		loadRoles();
	}, []);

	React.useEffect(() => {
		props.onChange({
			...props.dataItem,
			rolesAssigned: selectedRoles,
		});
	}, [selectedRoles]);

	const handleRolesChange = (value: string[]) => {
		const rolesToAssign = roles.filter((role) =>
			value?.some((eventRoleId) => eventRoleId === role.id.toString())
		);

		setSelectedRoles(rolesToAssign);
	};

	const userDisabledWarning = (): JSX.Element => {
		return (
			<>
				{!props.dataItem?.accountEnabled ? (
					<Box
						display="flex"
						flexDirection="row"
						alignItems="center"
						className="userDisabled-warning"
					>
						<ErrorOutlineIcon />
						{Texts.UsersView.EditUserForm.Tabs.AssignedRoles.DisabledUserWarning}
					</Box>
				) : null}
			</>
		);
	};

	const rolesData = roles?.map((role) => {
		return {
			id: role.id.toString(),
			roleName: role.roleName,
		};
	});

	const rolesValue = props.dataItem?.rolesAssigned?.map((role) => {
		return {
			id: role.id.toString(),
			roleName: role.roleName,
		};
	});

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="flex-start"
			className="editUser-tab-content rolesTab"
		>
			<Box display="flex" flexDirection="row" alignItems="center">
				<Typography variant="h6" className="tab-title" fontFamily="Poppins">
					{Texts.UsersView.EditUserForm.Tabs.AssignedRoles.Title}
				</Typography>

				{userDisabledWarning()}
			</Box>

			{loading ? (
				<CircularProgress />
			) : (
				<Box display="flex" flexDirection="column" style={{ minWidth: "400px" }}>
					<MultiselectFormField
						className="editUser-formField"
						data={rolesData}
						valueField="id"
						textField="roleName"
						value={rolesValue || []}
						onChange={handleRolesChange}
					/>
				</Box>
			)}
		</Box>
	);
};

export default connect(null, AlertStoreActionCreators)(AssignedRolesTab);
