import { Button, Collapse, IconButton, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Texts from "../../../../resources/Texts";
import CompanyDetailsTab from "./CompanyDetailsTab";
import "./EditCompanyForm.scss";
//import PersonIcon from "@mui/icons-material/Person";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
//import KeyIcon from "@mui/icons-material/Key";
import { ICompany, ICompanyView } from "../../../../resources/Contracts";
import { Service } from "../../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../../store/AlertStore";
import { handleErrorMessage } from "../../../../utils/utils";
import { connect } from "react-redux";
import isDomainValid from "../../../shared/utils";

enum EditCompanyFormTabs {
	Applications,
	Roles,
	Password,
	Details,
}

type EditCompanyFormProps = typeof AlertStoreActionCreators & {
	opened: boolean;
	closeCallback: () => void;
	//dataItem: ICompany;
	//updateCompany: (company: ICompany) => void;
	dataItem: ICompanyView;
	updateCompany: (company: ICompanyView) => void;
};

const EditCompanyForm: React.FC<EditCompanyFormProps> = (props) => {
	const [selectedTab, setSelectedTab] = React.useState<EditCompanyFormTabs>(
		EditCompanyFormTabs.Details
	);
	const [company, setCompany] = React.useState<ICompanyView>(props.dataItem);

	const handleTabChange = (event, value: EditCompanyFormTabs): void => {
		setSelectedTab(value);
	};

	const handleCancelClick = (): void => {
		setCompany(props.dataItem);

		props.closeCallback();
	};

	const handleSaveClick = async (): Promise<void> => {
		try {
			if (!await isDomainValid(company.domain)) {
				props.addErrorAlert(`${company.domain} is not a valid domain.`);
			} else {
				await Service.updateCompany(company);

				props.updateCompany(company);
			}
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));
		}

		props.closeCallback();
	};

	const handleChange = (newCompanyValue: ICompanyView) => {
		setCompany(newCompanyValue);
	};

	return (
		<Collapse in={props.opened} unmountOnExit sx={{ width: "95%" }}>
			<Box display="flex" flexDirection="column" className="editCompanyForm-container" flex={1}>
				<Box
					className="editCompanyForm-header"
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="flex-end"
				>
					<Tabs
						value={selectedTab}
						onChange={handleTabChange}
						className="editCompanyForm-tabs"
						TabIndicatorProps={{
							hidden: true,
						}}
					>
						<Tab
							label={
								<Box
									display="flex"
									flexDirection="row"
									className="tab-label"
									alignItems="center"
								>
									<ToggleOffIcon className="icon icon-toggle" />
									{Texts.CompaniesView.EditCompanyForm.Tabs.CompanyDetails.TabLabel}
								</Box>
							}
							value={EditCompanyFormTabs.Details}
							className="tab"
						/>
					</Tabs>

					<IconButton onClick={props.closeCallback}>
						<CloseIcon />
					</IconButton>
				</Box>

				{selectedTab === EditCompanyFormTabs.Details && (
					<CompanyDetailsTab dataItem={company} onChange={handleChange} />
				)}

				<Box
					display="flex"
					flexDirection="row"
					justifyContent="flex-end"
					className="buttons"
				>
					<Button
						className="button secondary-button"
						variant="contained"
						onClick={handleCancelClick}
					>
						{Texts.CompaniesView.EditCompanyForm.Buttons.Cancel}
					</Button>

					<Button
						className="button primary-button"
						variant="contained"
						onClick={handleSaveClick}
					>
						{Texts.CompaniesView.EditCompanyForm.Buttons.Save}
					</Button>
				</Box>
			</Box>
		</Collapse>
	);
};

export default connect(null, AlertStoreActionCreators)(EditCompanyForm);
