import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { IApplication, IUser, IUserApplication } from "../../../../resources/Contracts";
import Texts from "../../../../resources/Texts";
import { Service } from "../../../../services/Service";
import { MultiselectFormField } from "../../formControls/MultiselectFormField";
import "./AssignedApplicationsTab.scss";
import { actionCreators as AlertStoreActionCreators } from "../../../../store/AlertStore";
import { handleErrorMessage } from "../../../../utils/utils";
import { connect } from "react-redux";

type AssignedApplicationsTabProps = typeof AlertStoreActionCreators & {
	dataItem: IUser;
	onChange: (newUserValue: IUser) => void;
};

const AssignedApplicationsTab: React.FC<AssignedApplicationsTabProps> = (props) => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [apps, setApps] = React.useState<IApplication[]>([]);
	const [selectedApps, setSelectedApps] = React.useState<IApplication[]>([]);

	React.useEffect(() => {
		const loadApps = async (): Promise<void> => {
			setLoading(true);

			try {
				const result: IApplication[] = await Service.getApplications();
				setApps(result);

				const userApplications: IUserApplication[] = await Service.getUserApplicationsByUserId(
					props.dataItem.id
				);
				if (userApplications) {
					const selectedUserApplications = result.filter((app) =>
						userApplications?.some((ur) => app.id === ur.id)
					);
					setSelectedApps(selectedUserApplications);
                }
			} catch (error) {
				props.addErrorAlert(handleErrorMessage(error));
			}

			setLoading(false);
		};

		loadApps();
	}, []);

	React.useEffect(() => {
		props.onChange({
			...props.dataItem,
			appsAssigned: selectedApps,
		});
	}, [selectedApps]);

	const handleAppsChange = (value: string[]) => {
		const appsToAssign = apps.filter((app) =>
			value?.some((eventAppId) => eventAppId === app.id.toString())
		);

		setSelectedApps(appsToAssign);
	};

	const userDisabledWarning = (): JSX.Element => {
		return (
			<>
				{!props.dataItem?.accountEnabled ? (
					<Box
						display="flex"
						flexDirection="row"
						alignItems="center"
						className="userDisabled-warning"
					>
						<ErrorOutlineIcon />
						{Texts.UsersView.EditUserForm.Tabs.AssignedApps.DisabledUserWarning}
					</Box>
				) : null}
			</>
		);
	};

	const appsData = apps?.map((app) => {
		return {
			id: app.id.toString(),
			roleName: app.title,
		};
	});

	const appsValue = props.dataItem?.appsAssigned?.map((app) => {
		return {
			id: app.id.toString(),
			roleName: app.title,
		};
	});

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="flex-start"
			className="editUser-tab-content rolesTab"
		>
			<Box display="flex" flexDirection="row" alignItems="center">
				<Typography variant="h6" className="tab-title" fontFamily="Poppins">
					{Texts.UsersView.EditUserForm.Tabs.AssignedApps.Title}
				</Typography>

				{userDisabledWarning()}
			</Box>

			{loading ? (
				<CircularProgress />
			) : (
				<Box display="flex" flexDirection="column" style={{ minWidth: "400px" }}>
					<MultiselectFormField
						className="editUser-formField"
						data={appsData}
						valueField="id"
						textField="roleName"
						value={appsValue || []}
						onChange={handleAppsChange}
					/>
				</Box>
			)}
		</Box>
	);
};

export default connect(null, AlertStoreActionCreators)(AssignedApplicationsTab);
