const Texts = {
	Navigation: {
		Title: "Synamedia",
		SID: "Synamedia Identity",
		ManagementTools: "Manage",
		Companies: "Companies",
		Users: "Users",
		Login: "Log in",
		Logout: "Log out",
		Back: "Back",
	},
	UnauthorizedAccessMessage: "You are not authorised to use this application.",
	UnauthorizedMessage: "You are not logged in, please log in",
	HomeView: {
		Title: "Welcome to SID",
		Subtitle: "This tool allows you to manage customer access to the Synamedia Portals.",
	},
	UsersView: {
		Title: "Users",
		Subtitle: "Manage access to Synamedia resources for customers, partners and resellers.",
		AddUser: "Add User",
		Search: "Search",
		TableColumns: {
			Name: "Name",
			Login: "Login",
			Company: "Company",
			Status: "Status",
			Actions: "Actions",
		},
		NewUserForm: {
			CreateNewUser: "Create New User",
			PersonalDetails: {
				Title: "Personal Details",
				FirstName: "First name",
				LastName: "Last name",
				DisplayName: "Display name",
				Email: "Email / Login",
        Company: "Company",
        DynamicsId: "SynaCRM ID",
			},
			InitialPassword: {
				Title: "Initial Password",
				Hint: "Type a password, or click 'Suggest'",
				Suggest: "Suggest",
			},
			AssignedRoles: {
				Title: "Assigned Roles",
				Hint: "Select which roles this user can access",
			},
			WelcomeTemplate: {
				Title: "Welcome Template",
				Hint: "Select a template for the welcome email",
			},
			Cancel: "Cancel",
			Create: "Create",
		},
		EditUserForm: {
			Tabs: {
				AssignedApps: {
					TabLabel: "Assigned Applications",
					Title: "Enable applications for this user",
					DisabledUserWarning: "This user account is currently disabled",
				},
				AssignedRoles: {
					TabLabel: "Assigned Roles",
					Title: "Enable roles for this user",
					DisabledUserWarning: "This user account is currently disabled",
				},
				Password: {
					TabLabel: "Password",
					ResetPasswordTitle: "Reset User Password",
					PasswordHint: "Type a new password, or click 'Suggest' to generate one",
					PasswordActionNotice: "The new password will be emailed to the user",
					Suggest: "Suggest",
					TemplateTitle: "Choose Email Template",
					InternalHint: "For internal users, select 'none'",
					ExternalHint: "For external users, select an appropriate template",
				},
				PersonalDetails: {
					TabLabel: "Personal Details",
					Title: "Edit User Details",
					DisplayName: "Display name",
					FirstName: "First name",
					LastName: "Last name",
					Email: "Email / Login",
					Company: "Company",
				},
			},
			Buttons: {
				Cancel: "Cancel",
				Save: "Save",
			},
		},
	},
	CompaniesView: {
		Title: "Companies",
		Subtitle: "Add verified companies to the list.",
		AddCompany: "Add Company",
		Search: "Search",
		TableColumns: {
			ID: "ID",
			CompanyName: "Company Name",
			DynamicsId: "Account Number",
			Domain: "Domain",
			Description: "Description",
			Actions: "Actions",
		},
		NewCompanyForm: {
			CreateNewCompany: "Add from SynaCRM...",
			CompanyDetails: {
				Title: "Search CRM",
				ID: "ID",
				CompanyName: "CRM Account Name",
				DynamicsId: "Account Number",
				Domain: "Domain",
				Description: "Description",
			},
			Cancel: "Cancel",
			Create: "Add",
		},
		EditCompanyForm: {
			Tabs: {
				CompanyDetails: {
					TabLabel: "Company Details",
					Title: "Update Company",
					ID: "ID",
					CompanyName: "Company Name",
					DynamicsId: "Account Number",
					Domain: "Domain",
					Description: "Description",
				},
			},
			Buttons: {
				Cancel: "Cancel",
				Save: "Update",
			},
		},
	},
	Dialogs: {
		DeleteUserTitle: "Delete user",
		ConfirmEnable: "Confirm Enable",
		ConfirmEnableText: "You are about to activate the account of",
		ConfirmDisableText: "You are about to disable the account of",
		ConfirmDeleteText: "You are about to delete the account of",
		ConfirmDisable: "Confirm Disable",
		ConfirmationText: "Please confirm",
		Buttons: {
			ConfirmDialog: "OK",
			CancelDialog: "Cancel",
			DisableAccount: "Disable account",
			EnableAccount: "Enable account",
			DeleteAccount: "Delete account",
		},
	},
	Alerts: {
		InfoCompanyUpdated: "Company has been updated.",
		InfoCompanyNotCreatedMissingInfo: "Company has not been created. Please ensure you fill in all fields.",
		InfoUserUpdated: "User has been updated. It might take a while for the change to take effect.",
    	InfoUserNotCreatedMissingInfo: "User has not been created. Please ensure you fill in all fields and select a company.",
		InfoUserDeleted: "User has been deleted. It might take a while for the change to take effect.",
        SynamediaUser: "SID is for creating customer, partner and reseller users. Synamedia users should use their Synamedia credentials when logging into a support portal."
	},
};
export default Texts;
