import CompanyAddIcon from "@mui/icons-material/Castle";
import SortIcon from "@mui/icons-material/Sort";
import {
	Button,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ICompany, ICompaniesRequest, ICompaniesResponse, ICompanyView } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import { handleErrorMessage } from "../../../utils/utils";
import Pagination from "../../shared/Pagination";
import NewCompanyForm from "./companyForms/NewCompanyForm";
import "./CompaniesTable.scss";
import CompaniesTableRow from "./CompaniesTableRow";

type CompaniesTableProps = typeof AlertStoreActionCreators & {};

const CompaniesTable: React.FC<CompaniesTableProps> = (props) => {
	const [companiesData, setCompaniesData] = React.useState<ICompanyView[]>([]);
	const [newFormOpened, setNewFormOpened] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [searchText, setSearchText] = React.useState<string>("");
	
	// pagination
	const [previousPageDisabled, setPreviousPageDisabled] = React.useState<boolean>(true);
	const [nextPageDisabled, setNextPageDisabled] = React.useState<boolean>(false);
	const [currentViewPage, setCurrentViewPage] = React.useState<number>(0);
	const [pageIndex, setPageIndex] = React.useState<number>(1);
	const [totalPages, setTotalPages] = React.useState<number>(0);

	// The number of rows displayed, and the number of rows fetched from the db per page
	const numberOfResultsPerPage: number = 12;		// originally 20, but 12 results fit the screen better 

	let searchTimeout: NodeJS.Timeout;
	let companySearchTimeout: NodeJS.Timeout;
	
	// loadCompanies is triggered by a search, or on first load of the table
	const loadCompanies = React.useCallback(async () => {
		setLoading(true);

		try {
			const result: ICompaniesResponse = (await Service.getCompaniesTable(searchText, 1, numberOfResultsPerPage))
			if (result) {
				const companyViewData = result.data.items as ICompanyView[];
				setCompaniesData(companyViewData);
				setCurrentViewPage(0);
				setTotalPages(result.data.totalPages);
				setNextPageDisabled(!result.data.hasNextPage);
				setPreviousPageDisabled(!result.data.hasPreviousPage);
				setPageIndex(result.data.pageIndex);

				//// DEBUG ////
				//console.log("loadCompanies state pageIndex: " + pageIndex);
				//console.log("loadCompanies state currentViewPage: " + currentViewPage);
				//console.log("loadCompanies state totalPages: " + totalPages);
				//console.log("loadCompanies data pageIndex: " + result.data.pageIndex);
				//console.log("loadCompanies data totalPages: " + result.data.totalPages);
			}
			else{
				console.log("no companies");
			}
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));
		}
		setLoading(false);
	}, [searchText]);

	// first render
	React.useEffect(() => {
		loadCompanies();
	}, [loadCompanies]);
	
	const updateCompany = (updatedCompany: ICompanyView): void => {
		
		const companiesDataCopy: ICompanyView[] = companiesData.slice();
		const indexOfUpdatedCompany: number = companiesDataCopy.findIndex((u) => u.id === updatedCompany.id);
		companiesDataCopy[indexOfUpdatedCompany] = updatedCompany;

		setCompaniesData(companiesDataCopy);

		props.addAlert({
			type: "info",
			text: Texts.Alerts.InfoCompanyUpdated,
		}); 
	};

	const loadNewPageOfCompanies = async (loadPreviousPage: boolean) => {

		if (loadPreviousPage) {
			if(currentViewPage > 0) {
				const prevPage = currentViewPage - 1;
				setCurrentViewPage(prevPage);
				setNextPageDisabled(false);
			}
		} else {
			try {
				const totalPagesLoaded: number = Math.ceil(companiesData.length / numberOfResultsPerPage);

				// add +1 to currentpage, because currentPage starts from 0 for slicing purposes
				if (currentViewPage + 1 < totalPagesLoaded) {
					//console.log("In-memory companyData: (move next page) : currentViewPage=" + currentViewPage + ", totalPagesLoaded=" + totalPagesLoaded + ", pageIndex=" + pageIndex);
					setCurrentViewPage(currentViewPage + 1);
				} else {
					setLoading(true);
					const result: ICompaniesResponse = (await Service.getCompaniesTable(searchText, pageIndex+1, numberOfResultsPerPage)) // was pageIndex + 1
					if (result)	{
						const newCompanyViewData = result.data.items as ICompanyView[];
						const extendedCompaniesData: ICompanyView[] = companiesData.concat(newCompanyViewData);
						setCompaniesData(extendedCompaniesData);
						//console.log("Slice and dice companyData: (move next page) : currentViewPage=" + currentViewPage + ", totalPagesLoaded=" + totalPagesLoaded + ", pageIndex=" + pageIndex);
						setCurrentViewPage(result.data.pageIndex-1);
						setPageIndex(result.data.pageIndex);
						setNextPageDisabled(!result.data.hasNextPage);
						setPreviousPageDisabled(!result.data.hasPreviousPage);
						
						//// DEBUG ////
						//console.log("loadNewPageOfCompanies state pageIndex: " + pageIndex);
						//console.log("loadNewPageOfCompanies state currentViewPage: " + currentViewPage);
						//console.log("loadNewPageOfCompanies state totalPages: " + totalPages);
						//console.log("loadNewPageOfCompanies data pageIndex: " + result.data.pageIndex);
						//console.log("loadNewPageOfCompanies data totalPages: " + result.data.totalPages);

					} else {
						console.log("no results");
						setNextPageDisabled(true);
					}
					setLoading(false);
				}
			} catch (error) {
				props.addErrorAlert(handleErrorMessage(error));
			}
		}
	};

	const handleLoadNextPage = () => {
		if(!nextPageDisabled){
			loadNewPageOfCompanies(false);
		}
	};

	const handleLoadPrevPage = () => {
		if(!previousPageDisabled){
			loadNewPageOfCompanies(true);
		}
	};

	const handleNewFormOpen = (): void => {
		setNewFormOpened(true);
	};
	
	const handleSearchChange = (searchValue: string) => {
		clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearchText(searchValue);
		}, 500);
	};

	const tableColumnHeader = ( text: string, field: string, align: string, p0: string ): JSX.Element => {
			return (
			<TableCell
				className="companiesTable-headerCell"
			>
				<Box display="flex" flexDirection="row" alignItems="center" justifyContent={align}>
					<Typography variant="h6" fontFamily="Poppins">
						{text}
					</Typography>
				</Box>
			</TableCell>
		);
	};

	return (
		<Box display="flex" flexDirection="column" flex={1} className="companiesTable-container">
			<Box display="flex" flexDirection="row" justifyContent="space-between" flex={1}>
				<Button
					variant="contained"
					onClick={handleNewFormOpen}
					className="button primary-button button-addCompany"
				>
					<CompanyAddIcon className="icon icon-addCompany" />
					{Texts.CompaniesView.AddCompany}
				</Button>
				<TextField
					className="search-field"
					variant="outlined"
					label={Texts.UsersView.Search}
					size="small"
					type="search"
					onChange={(event) => handleSearchChange(event.target.value)}
				/>
			</Box>

			<Box sx={{ width: "100%" }}>
			<Pagination
					onNextPage={handleLoadNextPage}
					onPrevPage={handleLoadPrevPage}
					nextPageDisabled={nextPageDisabled}
					prevPageDisabled={previousPageDisabled}
					numberOfPages={totalPages}
					thisPage={currentViewPage+1} 
				/>
        <TableContainer className="companiesTable" >
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								{tableColumnHeader(
									Texts.CompaniesView.TableColumns.CompanyName,
									"companyName",
									"string",
									"left"
								)}
								{tableColumnHeader(
									Texts.CompaniesView.TableColumns.DynamicsId,
									"dynamicsId",
									"string",
									"left"
								)}
								{tableColumnHeader(
									Texts.CompaniesView.TableColumns.Domain,
									"domain",
									"string",
									"left"
								)}
								{tableColumnHeader(
									Texts.CompaniesView.TableColumns.Description,
									"description",
									"string",
									"left"
								)}
								<TableCell align="right" className="companiesTable-headerCell">
									<Typography
										variant="h6"
										sx={{ width: 120 }}
										fontFamily="Poppins"
									>
										{Texts.CompaniesView.TableColumns.Actions}
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell sx={{ padding: 0 }} colSpan={6}>
										<Box display="flex" className="companiesTable-loadingIndicator">
											<CircularProgress />
										</Box>
									</TableCell>
								</TableRow>
							) : (
								<>
									<TableRow>
										<TableCell colSpan={6} className="companiesTable-newCompanyRow">
											<NewCompanyForm
												opened={newFormOpened}
												reloadCompanies={loadCompanies}
												closeCallback={() => setNewFormOpened(false)}
											/>
										</TableCell>
									</TableRow>
									{companiesData?.slice(currentViewPage * numberOfResultsPerPage,	currentViewPage * numberOfResultsPerPage + numberOfResultsPerPage)
									.map((company) => (
										<CompaniesTableRow
											key={company.id}
											dataItem={company}
											updateCompany={updateCompany}
										/>
									))}
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>

				
			</Box>
		</Box>
	);
};

export default connect(null, AlertStoreActionCreators)(CompaniesTable);
