import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SortIcon from "@mui/icons-material/Sort";
import {
	Button,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { IUser, IUsersRequest, IUsersResponse } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import { handleErrorMessage } from "../../../utils/utils";
import GraphPagination from "../../shared/GraphPagination";
import NewUserForm from "./userForms/NewUserForm";
import "./UsersTable.scss";
import UsersTableRow from "./UsersTableRow";

type UsersTableProps = typeof AlertStoreActionCreators & {};
const UsersTable: React.FC<UsersTableProps> = (props) => {
	const [usersData, setUsersData] = React.useState<IUser[]>([]);
	const [sortDir, setSortDir] = React.useState<"asc" | "desc" | null>("asc");
	const [orderBy, setOrderBy] = React.useState<string>("displayName");
	const [orderByType, setOrderByType] = React.useState<"string" | "boolean" | null>(null);
	const [newFormOpened, setNewFormOpened] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [searchText, setSearchText] = React.useState<string>("");
	const [currentSkipToken, setCurrentSkipToken] = React.useState<string>("");
	const [newSkipToken, setNewSkipToken] = React.useState<string>("");
	const [nextPageDisabled, setNextPageDisabled] = React.useState<boolean>(false);
	const [currentPage, setCurrentPage] = React.useState<number>(0);
	const [totalPages, setTotalPages] = React.useState<number>(0);
	//const [allPagesLoaded, setAllPagesLoaded] = React.useState<boolean>(false);
	const [lastPage, setLastPage] = React.useState<number>(0);

	let searchTimeout: NodeJS.Timeout;
	const numberOfUsersPerPage: number = 12;    // was 20 

	const history = useHistory();

	const loadUsers = React.useCallback(async () => {
		setLoading(true);
		setCurrentPage(0);

		try {
			const requestBody: IUsersRequest = {
				filter: searchText,
				orderBy: orderBy,
				orderDir: sortDir,
				skipToken: newSkipToken,
				pageSize: numberOfUsersPerPage
			};

			const result: IUsersResponse = (await Service.getUsersData(requestBody))[0];

			const resultSkipToken: string = result?.queryOption?.find(
				(qo) => qo.name === "$skiptoken"
			)?.value;

			if (result) {
				setUsersData(result.users);
			}

			if (resultSkipToken) {
				setNewSkipToken(resultSkipToken);
				setNextPageDisabled(false);
			} else {
				setNextPageDisabled(true);
			}
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));
		}

		setLoading(false);
	}, [searchText, orderBy, sortDir]);

	React.useEffect(() => {
		loadUsers();
	}, [loadUsers]);

	const loadNewPageOfUsers = async (loadPreviousPage: boolean) => {
		if (loadPreviousPage) {
			setCurrentPage(currentPage - 1);
			setNextPageDisabled(false);
		} else {
			const totalNumberOfPages: number = Math.ceil(usersData.length / numberOfUsersPerPage);
			setTotalPages(totalNumberOfPages);
			if(currentPage+1 == lastPage) setNextPageDisabled(true);
			else setNextPageDisabled(false);
			//console.log("totalPages: " + totalNumberOfPages);
			//setNextPageDisabled(allPagesLoaded);

			//We need to add +1 to currentpage, because currentPage starts from 0 for slicing purposes
			if (currentPage + 1 < totalNumberOfPages) {
				setCurrentPage(currentPage+1);
				console.log("faf");
			} else {
				setLoading(true);
				setCurrentSkipToken(newSkipToken);

				try {
					const requestBody: IUsersRequest = {
						filter: searchText,
						orderBy: orderBy,
						orderDir: sortDir,
						skipToken: newSkipToken,
					};

					const result: IUsersResponse = (await Service.getUsersData(requestBody))[0];
					const resultSkipToken: string = result?.queryOption?.find(
						(qo) => qo.name === "$skiptoken"
					).value;

					if (resultSkipToken) {
						const extendedUserData: IUser[] = usersData.concat(result.users);
						setNewSkipToken(resultSkipToken);
						setUsersData(extendedUserData);
						setCurrentPage(currentPage + 1);
						setNextPageDisabled(false);
					} else {
						// graph does not return a skiptoken for the last page of results, so check if there were any Users returned
						if(result.users.length > 0){
							const lastPageOfUserData: IUser[] = usersData.concat(result.users);
							setUsersData(lastPageOfUserData);
							setCurrentPage(currentPage+1);
							setLastPage(currentPage+2);
							console.log("graph: NO skip token, current: " + currentPage);
						}
					}
				} catch (error) {
					props.addErrorAlert(handleErrorMessage(error));
				}

				setLoading(false);
			}
		}
	};

	const reloadUsers = async (): Promise<void> => {
		setLoading(true);
		setNewFormOpened(false);

		try {
			const requestBody: IUsersRequest = {
				filter: searchText,
				orderBy: orderBy,
				orderDir: sortDir,
				skipToken: currentSkipToken,
			};

			const result: IUsersResponse = (await Service.getUsersData(requestBody))[0];

			if (result) {
				setUsersData(result.users);
			}
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));
		}

		setLoading(false);
	};

	const updateUser = (updatedUser: IUser): void => {
		const usersDataCopy: IUser[] = usersData.slice();
		const indexOfUpdatedUser: number = usersDataCopy.findIndex((u) => u.id === updatedUser.id);

		usersDataCopy[indexOfUpdatedUser] = updatedUser;

		setUsersData(usersDataCopy);

		props.addAlert({
			type: "info",
			text: Texts.Alerts.InfoUserUpdated,
		});
	};

	const deleteUser = (userId: string): void => {
		const indexOfDeletedUser: number = usersData.findIndex((u) => u.id === userId);

		const usersDataCopy: IUser[] = usersData.slice();
		usersDataCopy.splice(indexOfDeletedUser, 1);

		setUsersData(usersDataCopy);

		props.addAlert({
			type: "info",
			text: Texts.Alerts.InfoUserDeleted,
		});

		setTimeout(() => {
			props.removeAlert();
		}, 5000);
	};

	// Sorting
	const handleSortChange = (orderByField: string, obt: typeof orderByType): void => {
		setCurrentSkipToken("");
		setNewSkipToken("");

		if (orderBy && orderBy === orderByField) {
			if (!sortDir) {
				setSortDir("asc");
			} else if (sortDir === "asc") {
				setSortDir("desc");
			} else if (sortDir === "desc") {
				setSortDir(null);
				setOrderBy(null);
				setOrderByType(null);
			}
		} else {
			setSortDir("asc");
			setOrderBy(orderByField);
			setOrderByType(obt);
		}
	};

	const handleLoadNextPage = () => {
		loadNewPageOfUsers(false);
	};

	const handleLoadPrevPage = () => {
		loadNewPageOfUsers(true);
	};

	const handleNewFormOpen = (): void => {
		setNewFormOpened(true);
	};

	const handleSearchChange = (searchValue: string) => {
		clearTimeout(searchTimeout);

		searchTimeout = setTimeout(() => {
			setCurrentSkipToken("");
			setNewSkipToken("");
			setSearchText(searchValue);
		}, 500);
	};

	const tableColumnHeader = (
		text: string,
		field: string,
		dataType: typeof orderByType,
		align: string
	): JSX.Element => {
		const iconClassName: string =
			field === orderBy ? "icon icon-sort selected" : "icon icon-sort";

		return (
			<TableCell
				onClick={() => handleSortChange(field, dataType)}
				className="usersTable-headerCell"
			>
				<Box display="flex" flexDirection="row" alignItems="center" justifyContent={align}>
					<Typography variant="h6" fontFamily="Poppins">
						{text}
					</Typography>
					<SortIcon className={iconClassName} />
				</Box>
			</TableCell>
		);
	};

	return (
		<Box display="flex" flexDirection="column" flex={1} className="usersTable-container">
			<Box display="flex" flexDirection="row" justifyContent="space-between" flex={1}>
				<Button
					variant="contained"
					onClick={handleNewFormOpen}
					className="button primary-button button-addUser"
				>
					<PersonAddIcon className="icon icon-addUser" />
					{Texts.UsersView.AddUser}
				</Button>
				<TextField
					className="search-field"
					variant="outlined"
					label={Texts.UsersView.Search}
					size="small"
					type="search"
					onChange={(event) => handleSearchChange(event.target.value)}
				/>
			</Box>

			<Box sx={{ width: "100%" }}>
			<GraphPagination
					onNextPage={handleLoadNextPage}
					onPrevPage={handleLoadPrevPage}
					nextPageDisabled={nextPageDisabled}
					prevPageDisabled={currentPage === 0}
					thisPage={currentPage + 1}
				/>
        <TableContainer className="usersTable" >
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								{tableColumnHeader(
									Texts.UsersView.TableColumns.Name,
									"displayName",
									"string",
									"left"
								)}
								<TableCell align="left" className="usersTable-headerCell">
									<Typography
										variant="h6"
										sx={{ width: 120 }}
										fontFamily="Poppins"
									>
										{Texts.UsersView.TableColumns.Company}
									</Typography>
								</TableCell>
								{tableColumnHeader(
									Texts.UsersView.TableColumns.Login,
									"userPrincipalName",
									"string",
									"left"
								)}
								<TableCell align="left" className="usersTable-headerCell">
									<Typography
										variant="h6"
										sx={{ width: 120 }}
										fontFamily="Poppins"
									>
										{Texts.UsersView.TableColumns.Status}
									</Typography>
								</TableCell>
							<TableCell align="right" className="usersTable-headerCell">
									<Typography
										variant="h6"
										sx={{ width: 120 }}
										fontFamily="Poppins"
									>
										{Texts.UsersView.TableColumns.Actions}
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell sx={{ padding: 0 }} colSpan={6}>
										<Box display="flex" className="usersTable-loadingIndicator">
											<CircularProgress />
										</Box>
									</TableCell>
								</TableRow>
							) : (
								<>
									<TableRow>
										<TableCell colSpan={6} className="usersTable-newUserRow">
											<NewUserForm
												opened={newFormOpened}
												reloadUsers={reloadUsers}
												closeCallback={() => setNewFormOpened(false)}
											/>
										</TableCell>
									</TableRow>
									{usersData
										?.slice(
											currentPage * numberOfUsersPerPage,
											currentPage * numberOfUsersPerPage +
												numberOfUsersPerPage
										)
										.map((user) => (
											<UsersTableRow
												key={user.id}
												dataItem={user}
												updateUser={updateUser}
												deleteUser={deleteUser}
											/>
										))}
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>

				
			</Box>
		</Box>
	);
};

export default connect(null, AlertStoreActionCreators)(UsersTable);
