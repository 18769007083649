import { Autocomplete, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { ICompany, IUser } from "../../../../resources/Contracts";
import Texts from "../../../../resources/Texts";
import { Service } from "../../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../../store/AlertStore";
import { handleErrorMessage } from "../../../../utils/utils";
import { TextFormField } from "../../formControls/TextFormField";
import "./PersonalDetailsTab.scss";

type PersonalDetailsTabProps = typeof AlertStoreActionCreators & {
	dataItem: IUser;
	onChange: (newUserValue: IUser) => void;
};

const PersonalDetailsTab: React.FC<PersonalDetailsTabProps> = ({
	dataItem,
	onChange,
	addErrorAlert,
}) => {
	const [companies, setCompanies] = React.useState<ICompany[]>([]);
	const [companyValue, setCompanyValue] = React.useState<ICompany>(null);
	const [searchValue, setSearch] = React.useState<string>(null);

	let companySearchTimeout: NodeJS.Timeout;

	const loadCompanies = React.useMemo(
		() => async (): Promise<void> => {
			try {
				const companies: ICompany[] = await Service.getCompanies(searchValue);

				setCompanies(companies);
			} catch (error) {
				addErrorAlert(handleErrorMessage(error));
			}
		},
		[searchValue, addErrorAlert]
	);

	const loadUserCompany = React.useMemo(
		() => async (): Promise<void> => {
			if (dataItem.companyName && dataItem.companyName !== companyValue?.companyName) {
				try {
					const companies: ICompany[] = await Service.getCompanies(dataItem.companyName);

					if (companies?.length > 0) {
						const userCompany = companies.find(
							(c) => c.dynamicsId === dataItem.companyId
						);

						setCompanyValue(userCompany);
					}
				} catch (error) {
					addErrorAlert(handleErrorMessage(error));
				}
			}
		},
		[dataItem, addErrorAlert]
	);

	React.useEffect(() => {
		loadCompanies();
	}, [loadCompanies]);

	React.useEffect(() => {
		loadUserCompany();
	}, [loadUserCompany]);

	const displayNameHandler = (value: string) => {
		const user: IUser = { ...dataItem };
		user.displayName = value;
		onChange(user);
	};

	const givenNameHandler = (value: string) => {
		const user: IUser = { ...dataItem };
		user.givenName = value;
		user.displayName = `${user?.givenName} ${user?.surname}`;
		onChange(user);
	};

	const surnameHandler = (value: string) => {
		const user: IUser = { ...dataItem };
		user.surname = value;
		user.displayName = `${user?.givenName} ${user?.surname}`;
		onChange(user);
	};

	const handleCompanyChange = (value: ICompany) => {
		const user: IUser = { ...dataItem };
		user.companyName = value?.companyName;
		user.companyId = value?.dynamicsId;
		onChange(user);
		setCompanyValue(value);
	};

	const handleCompanySearchChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	): void => {
		clearTimeout(companySearchTimeout);

		companySearchTimeout = setTimeout(() => {
			setSearch(event.target.value);
		}, 500);
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="flex-start"
			className="editUser-tab-content detailsTab"
			flex={1}
		>
			<Typography variant="h6" className="tab-title" fontFamily="Poppins">
				{Texts.UsersView.EditUserForm.Tabs.PersonalDetails.Title}
			</Typography>

			<Box display="flex" flexDirection="row" flex={1} width="100%">
				<Box
					display="flex"
					flexDirection="column"
					flex={1}
					className="editUser-detailColumn"
				>
					<TextFormField
						className="editUser-formField"
						label={Texts.UsersView.EditUserForm.Tabs.PersonalDetails.DisplayName}
						value={dataItem?.displayName}
						onChange={displayNameHandler}
					/>

					<TextFormField
						className="editUser-formField"
						label={Texts.UsersView.EditUserForm.Tabs.PersonalDetails.FirstName}
						value={dataItem?.givenName}
						onChange={givenNameHandler}
					/>

					<Autocomplete
						disablePortal
						className="formField newUser-formField"
						options={companies || []}
						value={companyValue}
						renderInput={(params) => (
							<TextField
								{...params}
								label={Texts.UsersView.NewUserForm.PersonalDetails.Company}
								onChange={handleCompanySearchChange}
								onBlur={() => loadCompanies()}
							/>
						)}
						getOptionLabel={(option: ICompany) => option.companyName}
						onChange={(event, value) => handleCompanyChange(value as ICompany)}
          />

          <TextFormField
            className="newUser-formField"
            label={Texts.UsersView.NewUserForm.PersonalDetails.DynamicsId}
            value={dataItem?.companyId}
            onChange={displayNameHandler}
          />

				</Box>

				<Box
					display="flex"
					flexDirection="column"
					flex={1}
					className="editUser-detailColumn"
				>
					<TextFormField
						className="editUser-formField"
						label={Texts.UsersView.EditUserForm.Tabs.PersonalDetails.Email}
						value={dataItem?.mail}
						onChange={undefined}
						disabled={true}
					/>

					<TextFormField
						className="editUser-formField"
						label={Texts.UsersView.EditUserForm.Tabs.PersonalDetails.LastName}
						value={dataItem?.surname}
						onChange={surnameHandler}
					/>
				</Box>

				<Box display="flex" flexDirection="column" className="filler" flex={1} />
			</Box>
		</Box>
	);
};

export default connect(null, AlertStoreActionCreators)(PersonalDetailsTab);
