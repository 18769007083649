import CloseIcon from "@mui/icons-material/Close";
import KeyIcon from "@mui/icons-material/Key";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import PersonIcon from "@mui/icons-material/Person";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import {
	Autocomplete,
	Button,
	Collapse,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { ICompany, IRole, ITemplate, IUser } from "../../../../resources/Contracts";
import { EmailTemplate } from "../../../../resources/Enums";
import Texts from "../../../../resources/Texts";
import { Service } from "../../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../../store/AlertStore";
import { handleErrorMessage } from "../../../../utils/utils";
import { MultiselectFormField } from "../../formControls/MultiselectFormField";
import { TextFormField } from "../../formControls/TextFormField";
import { PasswordHelper } from "../userForms/PasswordHelper";
import "./NewUserForm.scss";

type NewUserFormProps = typeof AlertStoreActionCreators & {
	opened: boolean;
	reloadUsers: () => void;
	closeCallback: () => void;
};

const blankUser: IUser = {
	id: "",
	surname: "",
	givenName: "",
	displayName: "",
	accountEnabled: true,
	userPrincipalName: "",
	rolesAssigned: [],
};

const NewUserForm: React.FC<NewUserFormProps> = (props) => {
	const [companies, setCompanies] = React.useState<ICompany[]>([]);
	const [roles, setRoles] = React.useState<IRole[]>([]);
	const [templates, setTemplates] = React.useState<ITemplate[]>([]);
	const [user, setUser] = React.useState<IUser>();

	const [companyValue, setCompanyValue] = React.useState<ICompany>(null);

	let companySearchTimeout: NodeJS.Timeout;

	React.useEffect(() => {
		loadCompanies();
		loadRoles();
		loadTemplates();

		setUser(blankUser);
	}, []);

	const loadCompanies = async (search?: string): Promise<void> => {
		try {
			const companies: ICompany[] = await Service.getCompanies(search);

			setCompanies(companies);
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));
		}
	};

	const loadRoles = async (): Promise<void> => {
		try {
			const roles: IRole[] = await Service.getRoles();

			setRoles(roles.sort((a, b) => (a.roleName < b.roleName ? -1 : 1)));
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));
		}
	};

	const loadTemplates = async (): Promise<void> => {
		try {
			const result: ITemplate[] = [
				{
					id: "1",
					name: "General - no specific portal",
				},
				{
					id: "2",
					name: "Software Portal",
        },
        {
          id: "3",
          name: "License Portal",
        },
        {
          id: "4",
          name: "Reseller Portal",
        },
			];

			result.unshift({
				id: "0",
				name: "None - do not send email",
			});

			setTemplates(result);
		} catch (error) {
      props.addErrorAlert(handleErrorMessage(error));
      props.addAlert({
        type: "info",
        text: Texts.Alerts.InfoUserUpdated,
      });
		}
	};

	const handleCancelClick = (): void => {
		props.closeCallback();
		setUser(blankUser);
	};

	const handleCreateClick = async (): Promise<void> => {
    try {

      let continueProcessing = true;
      let errorMessage = "";

	  if (user.mail?.toLowerCase().includes('@synamedia.com')) {
		errorMessage = Texts.Alerts.SynamediaUser;
		continueProcessing = false;
	  } else {
		errorMessage = "Cannot create user - please complete the missing fields: ";
		if (!user.companyName) { errorMessage += "[Company] "; continueProcessing = false; }
		if (!user.companyId) { errorMessage += "[SynaCRM ID] "; continueProcessing = false; }
		if (!user.displayName) { errorMessage += "[Display name] "; continueProcessing = false; }
		if (!user.emailTemplate) { errorMessage += "[Welcome Template] "; continueProcessing = false; }
		if (!user.givenName) { errorMessage += "[First name] "; continueProcessing = false; }
		if (!user.mail) { errorMessage += "[Email / Login] "; continueProcessing = false; }
		if (!user.password) { errorMessage += "[Initial Password] "; continueProcessing = false; }
		if (user.rolesAssigned.length < 1) { errorMessage += "[Assigned Roles] "; continueProcessing = false; }
		if (!user.surname) { errorMessage += "[Last name] "; continueProcessing = false; }
	  }

      if (continueProcessing) {
        await Service.addUser(user);
        props.reloadUsers();
        props.addAlert({ type: "info", text: "User " + user.displayName + " was created successfully", });
      }
      else {
        props.addAlert({ type: "warning", text: errorMessage, });
      }
    } catch (error) {
      props.addErrorAlert(handleErrorMessage(error));
		}
	};

	const givenNameHandler = (value: string) => {
		const newUser: IUser = { ...user };
		newUser.givenName = value;
		newUser.displayName = `${newUser?.givenName} ${newUser?.surname}`;
		setUser(newUser);
	};

	const surnameHandler = (value: string) => {
		const newUser: IUser = { ...user };
		newUser.surname = value;
		newUser.displayName = `${newUser?.givenName} ${newUser?.surname}`;
		setUser(newUser);
	};

	const displayNameHandler = (value: string) => {
		const newUser: IUser = { ...user };
		newUser.displayName = value;
		setUser(newUser);
	};

	const emailHandler = (value: string) => {
		const newUser: IUser = { ...user };
		newUser.mail = value;
		setUser(newUser);
	};

	const handleCompanyChange = (value: ICompany) => {
		const newUser: IUser = { ...user };
		newUser.companyName = value?.companyName;
		newUser.companyId = value?.dynamicsId;
		setUser(newUser);
		setCompanyValue(value);
	};

	const handleCompanySearchChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	): void => {
		clearTimeout(companySearchTimeout);

		companySearchTimeout = setTimeout(() => {
			loadCompanies(event.target.value);
		}, 500);
	};

	const passwordHandler = (value: string) => {
		const newUser: IUser = { ...user };
		newUser.password = value;
		setUser(newUser);
	};

	const suggestClickHandler = (): void => {
		const newPass = PasswordHelper.GenerateNewPassword(4, 4, 4);
		const newUser: IUser = { ...user };
		newUser.password = newPass;
		setUser(newUser);
	};

	const handleRolesChange = (value: string[]) => {
		const newUserValue: IUser = { ...user };

		const rolesToAssign = roles.filter((role) =>
			value?.some((eventRoleId) => eventRoleId === role.id.toString())
		);

		newUserValue.rolesAssigned = rolesToAssign;

		setUser(newUserValue);
	};

	const rolesData = roles.map((role) => {
		return {
			id: role.id.toString(),
			roleName: role.roleName,
		};
	});

	const rolesValue = user?.rolesAssigned?.map((role) => {
		return {
			id: role.id.toString(),
			roleName: role.roleName,
		};
	});

	const handleClickTemplate = (key: string) => {
		const newUserValue: IUser = { ...user };

		let emailTemplate = EmailTemplate.None;
		switch (key) {
			case "1":
				emailTemplate = EmailTemplate.GeneralWelcome;
				break;

			case "2":
				emailTemplate = EmailTemplate.SoftwarePortal;
				break;

      case "3":
        emailTemplate = EmailTemplate.LicensePortal;
        break;

      case "4":
        emailTemplate = EmailTemplate.ResellerPortal;
        break;
    }
		newUserValue.emailTemplate = emailTemplate;
		setUser(newUserValue);
	};

	return (
		<Collapse in={props.opened} unmountOnExit sx={{ width: "100%" }}>
			<Box display="flex" flexDirection="column" className="newUser-container" flex={1}>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography variant="h6" fontFamily="Poppins">
						{Texts.UsersView.NewUserForm.CreateNewUser}
					</Typography>

					<IconButton onClick={props.closeCallback}>
						<CloseIcon />
					</IconButton>
				</Box>

				<Box display="flex" flexDirection="row" className="newUser-columns" flex={1}>
					<UserFormColumn
						title={Texts.UsersView.NewUserForm.PersonalDetails.Title}
						icon={<PersonIcon className="icon icon-person" />}
					>
						<TextFormField
							className="newUser-formField"
							label={Texts.UsersView.NewUserForm.PersonalDetails.FirstName}
							value={user?.givenName}
							onChange={givenNameHandler}
						/>

						<TextFormField
							className="newUser-formField"
							label={Texts.UsersView.NewUserForm.PersonalDetails.LastName}
							value={user?.surname}
							onChange={surnameHandler}
						/>

						<TextFormField
							className="newUser-formField"
							label={Texts.UsersView.NewUserForm.PersonalDetails.DisplayName}
							value={user?.displayName}
							onChange={displayNameHandler}
						/>

						<TextFormField
							className="newUser-formField"
							label={Texts.UsersView.NewUserForm.PersonalDetails.Email}
							value={user?.mail}
							onChange={emailHandler}
						/>

						<Autocomplete
							disablePortal
							className="formField newUser-formField"
							options={companies || []}
							value={companyValue}
							renderInput={(params) => (
								<TextField
									{...params}
									label={Texts.UsersView.NewUserForm.PersonalDetails.Company}
									onChange={handleCompanySearchChange}
									onBlur={() => loadCompanies()}
								/>
							)}
							getOptionLabel={(option: ICompany) => option.companyName}
							onChange={(event, value) => handleCompanyChange(value as ICompany)}
            />

            <TextFormField
              className="newUser-formField"
              label={Texts.UsersView.NewUserForm.PersonalDetails.DynamicsId}
              value={user?.companyId}
              onChange={displayNameHandler}
            />

					</UserFormColumn>

					<UserFormColumn
						title={Texts.UsersView.NewUserForm.InitialPassword.Title}
						icon={<KeyIcon className="icon icon-key" />}
					>
						<Box className="passwordInput-hint">
							{Texts.UsersView.NewUserForm.InitialPassword.Hint}
						</Box>

						<TextFormField
							className="newUser-formField"
							value={user?.password}
							onChange={passwordHandler}
						/>

						<Button
							variant="contained"
							className="suggestPassword-button"
							onClick={suggestClickHandler}
						>
							<LightbulbIcon />
							{Texts.UsersView.NewUserForm.InitialPassword.Suggest}
						</Button>
					</UserFormColumn>





					<UserFormColumn
						title={Texts.UsersView.NewUserForm.AssignedRoles.Title}
						icon={<ToggleOffIcon className="icon icon-toggle" />}
					>
						<Box className="roles-hint">
							{Texts.UsersView.NewUserForm.AssignedRoles.Hint}
						</Box>

						<Box display="flex" flexDirection="column">
							<MultiselectFormField
								className="editUser-formField"
								data={rolesData}
								valueField="id"
								textField="roleName"
								value={rolesValue || []}
								onChange={handleRolesChange}
							/>
						</Box>
					</UserFormColumn>

					<UserFormColumn
						title={Texts.UsersView.NewUserForm.WelcomeTemplate.Title}
						icon={<MarkEmailReadIcon className="icon icon-envelope" />}
					>
						<Box className="templates-hint">
							{Texts.UsersView.NewUserForm.WelcomeTemplate.Hint}
						</Box>

						<Box display="flex" flexDirection="column">
							<RadioGroup>
								{templates.map((template) => (
									<FormControlLabel
										className="template-radio"
										key={template.id}
										value={template.id}
										control={<Radio />}
										label={template.name}
										onClick={() => handleClickTemplate(template.id)}
									/>
								))}
							</RadioGroup>
						</Box>
					</UserFormColumn>
				</Box>

				<Box
					display="flex"
					flexDirection="row"
					justifyContent="flex-end"
					alignItems="center"
				>
					<Button
						className="button secondary-button"
						variant="contained"
						onClick={handleCancelClick}
					>
						{Texts.UsersView.NewUserForm.Cancel}
					</Button>

					<Button
						className="button primary-button"
						variant="contained"
						onClick={handleCreateClick}
					>
						{Texts.UsersView.NewUserForm.Create}
					</Button>
				</Box>
			</Box>
		</Collapse>
	);
};

export default connect(null, AlertStoreActionCreators)(NewUserForm);

interface UserFormColumnProps {
	title: string;
	icon: JSX.Element;
}

const UserFormColumn: React.FC<UserFormColumnProps> = (props) => {
	return (
		<Box className="newUser-column" display="flex" flexDirection="column" flex={1}>
			<Box
				className="newUser-column-header"
				display="flex"
				flexDirection="row"
				alignItems="center"
			>
				{props.icon}
				{props.title}
			</Box>

			{props.children}
		</Box>
	);
};
