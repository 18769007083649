import {
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Checkbox,
	ListItemText,
	Box,
	Chip,
} from "@mui/material";
import * as React from "react";

interface MultiselectFormFieldProps {
	data: any[];
	valueField: string;
	textField: string;
	className?: string;
	label?: string;
	value: any[];
	onChange: (value: string[]) => void;
	input?: JSX.Element;
}

export const MultiselectFormField: React.FC<MultiselectFormFieldProps> = (props) => {
	const handleChange = (event: SelectChangeEvent<any[]>) => {
		let result: string[] = [];

		if (event.target.value) {
			if (typeof event.target.value === "string") {
				result = event.target.value.split(",");
			} else {
				event.target.value.forEach((x) => {
					if (typeof x === "string") {
						if (
							result.some(
								(resultItem) =>
									resultItem === x || resultItem[props.valueField] === x
							)
						) {
							result = result.filter(
								(resultItem) =>
									resultItem !== x && resultItem[props.valueField] !== x
							);
						} else {
							result.push(x);
						}
					} else {
						if (
							result.some(
								(resultItem) =>
									resultItem === x[props.valueField] ||
									resultItem[props.valueField] === x[props.valueField]
							)
						) {
							result = result.filter(
								(resultItem) =>
									resultItem !== x[props.valueField] &&
									resultItem[props.valueField] !== x[props.valueField]
							);
						} else {
							result.push(x[props.valueField]);
						}
					}
				});
			}
		}

		props.onChange(result);
	};

	const className: string = props.className
		? `formField formField-multiselect ${props.className}`
		: "formField formField-multiselect";

	return (
		<>
			{props.label && <InputLabel className="formField-label">{props.label}</InputLabel>}

			<Select
				value={props.value || []}
				onChange={handleChange}
				className={className}
				variant="filled"
				multiple
				renderValue={(selected) => (
					<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
						{selected.map((value) => (
							<Chip key={value[props.valueField]} label={value[props.textField]} />
						))}
					</Box>
				)}
				input={props.input}
			>
				{props.data?.map((item) => (
					// <MenuItem value={item[props.valueField]} key={item?.id}>
					// 	{item[props.textField]}
					// </MenuItem>

					<MenuItem
						value={item[props.valueField]}
						key={item?.id}
						className="formField-multiselect-item"
					>
						<Checkbox
							checked={
								props.value?.some(
									(x) => x[props.valueField] === item[props.valueField]
								) || false
							}
						/>
						<ListItemText primary={item[props.textField]} />
					</MenuItem>
				))}
			</Select>
		</>
	);
};
