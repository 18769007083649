import { Box } from "@mui/system";
import * as React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./Pagination.scss";
import { IconButton } from "@mui/material";

interface PaginationProps {
	onNextPage: () => void;
	onPrevPage: () => void;
	nextPageDisabled: boolean;
	prevPageDisabled: boolean;
	thisPage: number;
	numberOfPages: number;
}

const Pagination: React.FC<PaginationProps> = (props) => {
	return (
		<Box
			className="pagination-wrapper"
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="flex-end"
			flex={1}
		>
			<IconButton disabled={props.prevPageDisabled} onClick={props.onPrevPage}>
				<ArrowBackIosIcon />
			</IconButton>
			{props.thisPage} / {props.numberOfPages}
			<IconButton disabled={props.nextPageDisabled} onClick={props.onNextPage}>
				<ArrowForwardIosIcon />
			</IconButton>
		</Box>
	);
};

export default Pagination;
