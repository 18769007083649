import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import "./CompanyAdministration.scss";
import UsersTable from "../../../components/administration/companies/CompaniesTable";
import Texts from "../../../resources/Texts";

interface CompanyAdministrationProps {}

const CompanyAdministration: React.FunctionComponent<CompanyAdministrationProps> = () => {
	return (
		<Box className="companyAdministration">
      <Container maxWidth={false} disableGutters>
				<Box display="flex" flexDirection="column" className="companyAdministration-title">
					<Typography variant="h4" component="div" fontFamily="Poppins">
						{Texts.CompaniesView.Title}
					</Typography>

					<Typography variant="subtitle1" component="div" fontFamily="Poppins">
						{Texts.CompaniesView.Subtitle}
					</Typography>
				</Box>

				<UsersTable />
			</Container>
		</Box>
	);
};

export default CompanyAdministration;
